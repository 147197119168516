import React from "react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import SlideEpaviste from "../components/landing/slideEpaviste"
import Description from "../components/landing/description"
import Document from "../components/landing/document"
import Faq from "../components/landing/faq"
import Step from "../templates/department/components/step"
import Questions from "../templates/department/components/questions"

const Epaviste = () => (
  <Layout>
    <Seo
      title="Epaviste Dulin, épaviste agrée pour l'envelement d'épave en Ile de France"
      description="Formulaire de contact, déscription des formalités pour enlever une épave gratuitement par nos service"
    />
    <SlideEpaviste 
      backgroundImage={"epave"}
      para={"Pour convenir d'un rendez-vous afin d'enlever votre épave gratuitement,"}
    />
    <Description
      titre={"Quel est le rôle de l’épaviste ?"}
      para={"Nous intervenons de manière gratuite en Ile de France pour enlever votre véhicule. À l’aide de nos camions de remorquages, nous venons prendre votre véhicule pour la transporter dans notre centre de recyclage agréé. Ce centre permet de traiter le véhicule afin d’éliminer ses éléments toxiques tels que l’huile du moteur, le caoutchouc, etc. Le service est entièrement gratuit, vous devez simplement nous fournir certains documents."}
    />
    <Step />
    <Document 
      titre={"Les documents à fournir pour faire enlever votre épave"}
    />
    <Questions />
    <Faq />
  </Layout>
)

export default Epaviste

